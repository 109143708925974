import React, { forwardRef, useRef, useState, useEffect } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navbar from "../../components/navbar";
import FreeTrial from "../../components/free-trial";
import Footer from "../../components/footer";
import LeftFeature from "../../components/left-feature";
import FeaturePararaph from "../../components/feature-paragraph";
import multiDeviceSrc from "../../images/multi-device.png";
import logoDarkSrc from "../../images/logo-dark.svg";
import printIconSrc from "../../images/print-icon.svg";
import { useReactToPrint } from "react-to-print";

const TimesheetCalculatorComponent = forwardRef((props, ref) => {
  const [timesheetData, setTimesheetData] = useState({
    monday: { startTime: '', endTime: '', breakTime: '' },
    tuesday: { startTime: '', endTime: '', breakTime: '' },
    wednesday: { startTime: '', endTime: '', breakTime: '' },
    thursday: { startTime: '', endTime: '', breakTime: '' },
    friday: { startTime: '', endTime: '', breakTime: '' },
    saturday: { startTime: '', endTime: '', breakTime: '' },
    sunday: { startTime: '', endTime: '', breakTime: '' },
  });

  const [contractedHours, setContractedHours] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [overtimeRate, setOvertimeRate] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalOvertime, setTotalOvertime] = useState(0);
  const [totalPay, setTotalPay] = useState(0);

  const handleInputChange = (day, field, value) => {
    setTimesheetData((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    calculateTotals();
  }, [timesheetData, contractedHours, hourlyRate, overtimeRate]);

  const calculateTotals = () => {
    let totalHours = 0;
    let totalOvertime = 0;

    // Calculate total hours for the week
    for (const day in timesheetData) {
      const { startTime, endTime, breakTime } = timesheetData[day];
      if (startTime && endTime) {
        const start = new Date(`01/01/2000 ${startTime}`);
        let end = new Date(`01/01/2000 ${endTime}`);

        if (end < start) {
          // Add a day to end time if end time is in next day
          end = new Date(end.getTime() + 24 * 60 * 60 * 1000);
        }

        const breakMinutes = parseFloat(breakTime) || 0;
        const breakHours = breakMinutes / 60;
        const diff = (end - start) / 1000 / 60 / 60 - breakHours;
        totalHours += diff;
      }
    }

    if(totalHours < 0){
      totalHours = 0
    }

    // If contracted hours is set to 0, consider all hours as regular working hours
    if (contractedHours === 0) {
      setTotalHours(totalHours);
      setTotalOvertime(0);
      setTotalPay(totalHours * hourlyRate);
      return;
    }

    // Calculate total overtime for the week
    if (totalHours > contractedHours) {
      totalOvertime = totalHours - contractedHours;
      totalHours -= totalOvertime;
    }

    // Calculate total pay
    let pay = totalHours * hourlyRate;
    if (totalOvertime > 0) {
      const overtimePay = totalOvertime * overtimeRate;
      pay += overtimePay;
    }

    setTotalHours(Math.max(totalHours, 0));
    setTotalOvertime(Math.max(totalOvertime, 0));
    setTotalPay(Math.max(pay, 0));
  };

  return (
    <div className="print-sheet" ref={ref}>
      <div className="flex print-show mb-4">
        <img className="w-1/4" src={logoDarkSrc} alt="Time Management" />
      </div>
      <form className="-mt-2 ">
        <div className="border-b py-4 border-gray-200">
        {Object.keys(timesheetData).map((day, index) => (
          <div key={index}>
            <div className="grid grid-cols-6 xl:grid-cols-8 gap-1 mt-2 items-center">
              <div className="col-span-8 xl:col-span-2">
                {index === 0 && <label className="text-sm font-semibold text-highlight-600 mb-2 print-hide ">Day</label>}
                <h3 className="text-base font-semibold capitalize py-2 form-input">{day}</h3>
              </div>
              <div className="col-span-8 xl:col-span-6 flex items-center">
                <div className="w-full flex gap-4">
                  <div className="flex-1 flex flex-col">
                    {index === 0 && <label className="text-sm font-semibold text-highlight-600 mb-2 ">Start Time</label>}
                    <input
                      type="time"
                      value={timesheetData[day].startTime}
                      onChange={(e) => handleInputChange(day, "startTime", e.target.value)}
                      className="form-input rounded-md border-highlight-400 border-2 py-2 px-3 w-full focus:outline-none transition ease-in-out duration-150 flex-1"
                    />
                  </div>
                  <div className="flex-1 flex flex-col">
                    {index === 0 && <label className="text-sm font-semibold text-highlight-600 mb-2">End Time</label>}
                    <input
                      type="time"
                      value={timesheetData[day].endTime}
                      onChange={(e) => handleInputChange(day, "endTime", e.target.value)}
                      className="form-input rounded-md border-highlight-400 border-2 py-2 px-3 w-fugapll focus:outline-none transition ease-in-out duration-150 flex-1"
                    />
                  </div>
                  <div className="flex-1 flex flex-col">
                    {index === 0 && <label className="text-sm font-semibold text-highlight-600 mb-2">Break (Mins)</label>}
                    <input
                      type="number"
                      min="0"
                      value={timesheetData[day].breakTime}
                      onChange={(e) => handleInputChange(day, "breakTime", e.target.value ? Math.max(e.target.value, 0) : '')}
                      className="form-input rounded-md border-highlight-400 border-2 py-2 px-3 w-full focus:outline-none transition ease-in-out duration-150 flex-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        <div className="my-4 grid grid-cols-2 gap-3 ">
          <label className="text-base font-semibold self-center">Weekly Contracted Hours:</label>
          <input
            type="number"
            min="0"
            className="form-input rounded-md border-highlight-400 border-2 py-2 px-3 focus:outline-none transition ease-in-out duration-150"
            value={contractedHours}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              setContractedHours(value);
            }}
          />

          <label className="text-base font-semibold self-center">Hourly Rate (£):</label>
          <input
            type="number"
            min="0"
            step="0.01"
            className="form-input rounded-md border-highlight-400 border-2 py-2 px-3 focus:outline-none transition ease-in-out duration-150"
            value={hourlyRate}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              setHourlyRate(value);
            }}
          />

          <label className="text-base font-semibold self-center">Overtime Rate (£):</label>
          <input
            type="number"
            min="0"
            step="0.01"
            className="form-input rounded-md border-highlight-400 border-2 py-2 px-3 focus:outline-none transition ease-in-out duration-150"
            value={overtimeRate}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              setOvertimeRate(value);
            }}
          />
        </div>
      </form>
      <div className="mb-4 text-sm font-semibold text-right mt-2">
        <p className="text-base">
          Total Hours: <span className="text-highlight-600">{totalHours.toFixed(2)} ({Math.floor(totalHours)} hours{" "}
          {((totalHours - Math.floor(totalHours)) * 60).toFixed(0)} minutes)</span>
        </p>
        <p className="text-base">
          Total Overtime: <span className="text-highlight-600">{totalOvertime.toFixed(2)} ({Math.floor(totalOvertime)} hours{" "}
          {((totalOvertime - Math.floor(totalOvertime)) * 60).toFixed(0)} minutes)</span>
        </p>
        <p className="text-base">Total Pay: <span className="text-highlight-600">£{totalPay ? totalPay.toFixed(2) : '0.00'}</span></p>
      </div>
    </div>
  );
});

const TimesheetCalculator = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Layout>
      <SEO
        title="Timesheet Calculator"
        description="Calculate your weekly working hours and pay with our simple timesheet calculator."
      />
      <Navbar />
      <div className="bg-gray-50">
        <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="relative pt-12 pb-16 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-3 gap-5">
              <div className="lg:col-span-2 mb-8 lg:mb-0 flex flex-col">
                <TimesheetCalculatorComponent ref={componentRef} />
                <div className="flex justify-end">
                <button
                  onClick={handlePrint}
                  className="inline-flex items-center justify-center bg-highlight-600 text-white py-2 px-4 rounded-md hover:bg-highlight-700 focus:outline-none focus:bg-highlight-700 transition duration-150 ease-in-out"
                  style={{ width: "fit-content" }}
                >
                  <img className="h-5 mr-2" src={printIconSrc} alt="Print Timesheet" />
                  Print
                </button>
                </div>
              </div>
              <div className="lg:col-span-1 lg:pl-8">
                <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                  <p className="leading-6 text-highlight-600 font-semibold tracking-tight uppercase">
                    Timesheet Calculator
                  </p>
                  <h1
                    className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Calculate an employee's weekly timesheet with our free calculator.
                  </h1>
                  <div className="prose text-gray-500">
                    <p>This simple timesheet calculator helps you to compute an employee's weekly working hours and pay.
                    </p>
                    <p>Here's how to use it:</p>
                    <ol>
                      <li>For each day of the week, enter the start time, end time, and any break time taken.</li>
                      <li>Enter the total number of contracted hours for the week.</li>
                      <li>Input the hourly rate for the employee.</li>
                      <li>Input the overtime rate for the employee.</li>
                      <li>The calculator will display the total hours worked, total overtime (if any), and the total pay
                        based on the entered data.
                      </li>
                      <li>You can also click the "Print" button to print out the calculated timesheet.</li>
                    </ol>
                    <p>Use this calculator to efficiently manage your employees' working hours and ensure accurate
                      payroll calculations.</p>
                    <p>Remember, with great power comes great payroll responsibility – and maybe a few less
                      headaches!</p>
                  </div>
                </div>
              </div>
            </div>
            <LeftFeature
              sectionTitle="Stop doing manual timesheets forever"
              title="Move to Cloud-based Time and Attendance Software"
              body={
                <div>
                  <FeaturePararaph>
                    The above calculation is just one of many pains in managing employee time and attendance.
                  </FeaturePararaph>
                  <FeaturePararaph>
                    If you had a few minutes, we'd love for you to check out TimeKeeper. Our simple solution automates
                    the
                    pain of employee timesheets, saving you time and money so you can focus on other tasks or just
                    relax!
                  </FeaturePararaph>
                </div>
              }
              imgSrc={multiDeviceSrc}
              imgAlt="Move to Cloud Based Time and Attendance Software"
            />
          </div>
        </div>
      </div>
      <FreeTrial showDemoBooking={true} part1={<span>Ready to make the first step to <span
        className="text-highlight-600">automated</span> <br /> employee timesheets?</span>} />
      <Footer />
    </Layout>
  )
};

export default TimesheetCalculator;
